//Homepage.jsx


import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../../CSS/Services/Ecommerce/Homepage.css";

function Homepage() {
  return (
    <div className='homepageheading'>
      <h2>Empowering Businesses with Exceptional E-Commerce Solutions for Seamless Growth and Customer Satisfaction</h2>
      <div className='homepage'>
        <div className='homepageimg'>
          <Carousel showArrows={false} showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true}  interval={2000} transitionTime={1000}>
            <div>
               <img src="../Images/homepageimg1.png" alt="E-commerce image" className="ecommerce" />
            </div>
            <div>
               <img src="../Images/homepageimg2.png" alt="E-commerce image" className="ecommerce" />
            </div>
            <div>
               <img src="../Images/homepageimg3.png" alt="E-commerce image" className="ecommerce" />
            </div>
            <div>
              <img src="../Images/homepageimg4.png" alt="E-commerce image" className="ecommerce" />
            </div>
          </Carousel>
        </div>
        <div className='homepagecontent'>
          <p>Transform your e-commerce venture with a platform that beautifully showcases your products and delivers exceptional performance.</p>
          <ul>
            <li>Secure payment gateways.</li>
            <li>Advanced SEO techniques.</li>
            <li>An intuitive user interface for unparalleled success.</li>
            <li>Maximize your business potential with scalable infrastructure.</li>  
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Homepage;






