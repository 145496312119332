import React from 'react'
import Webhomepage from './Webhomepage'
import Websteps from './Websteps'
import Methodology from './Methodology'
import Technologies from './Technologies'
import WebPartnership from './WebDev-Partnership'
import WebDevrequestcallback from './WebDevrequestcallback'

export default function WebDev() {
  return (
    <div>
      <Webhomepage />
      <Websteps />
      <Methodology />
      <Technologies />
      <WebPartnership />
      <WebDevrequestcallback />
    </div>
  )
}
