import React, { useEffect, useRef } from 'react';
import '../../CSS/Home/Homevdo.css';

const Homevdo = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error('Auto-play failed:', error);
      });
    }
  }, []);

  const toggleFullScreen = () => {
    if (videoRef.current) {
      if (!document.fullscreenElement) {
        videoRef.current.requestFullscreen().catch((err) => {
          console.error('Failed to enter fullscreen:', err);
        });
      } else {
        document.exitFullscreen();
      }
    }
  };

  return (
    <div className="video-container" onClick={toggleFullScreen}>
      <video
        ref={videoRef}
        className="fullscreen-video"
        autoPlay
        loop
        muted
        playsInline
        controls={false} // Disable default video controls
        onClick={(e) => e.preventDefault()} // Prevent controls from appearing on click
      >
        <source src="/homevdo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};


export default Homevdo;