import React from 'react';
import '../../../CSS/Services/DigiMarket/Digihome.css';
function Digihome() {
  return (
    <div className='DigiContainer'>
        <img src='../Images/digi2.png' alt="digital marketing" id="digitalimg" />
        
       <div className='digi-content'>
             <h2 className='digi-content-h2'>Elevate Your Online Presence with Expert Digital Marketing Solutions</h2>
             <p className='digi-content-p'>At theLogicalMonks, we understand the dynamic landscape of digital
                marketing and offer tailored strategies to help your business thrive in
                the digital realm. Our comprehensive suite of digital marketing services
                is designed to boost your brand visibility, engage your target audience,
                and drive conversions. </p>
        </div>
    </div>
  )
}

export default Digihome;