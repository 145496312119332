import React from 'react';
import Homevdo from './Homevdo';


export default function Home() {
  return (
    <div>
      <Homevdo />  
    </div>
  )
}
