import React, { useState, useEffect } from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import '../../CSS/AboutUs/About.css';

function About() {
    const [expanded, setExpanded] = useState(false);


    const handleReadMore = () => {
        if (window.innerWidth <= 600) {
            
            document.querySelector('.modal').style.display = 'flex';
        } else {
          
            setExpanded(!expanded);
        }
    };


    const closeModal = () => {
        document.querySelector('.modal').style.display = 'none';
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 600 && document.querySelector('.modal').style.display === 'flex') {
                closeModal();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="about-div">
            <img src="../Images/aboutus3.png" alt="aboutus" className={`aboutus-img ${expanded ? 'fixed-image' : ''}`} />
            
            <div className="aboutus-content">
                <h1 className="aboutus-h1">ABOUT US</h1>
                <h4 className="aboutus-h4">WHO WE ARE</h4>
                <p className="aboutus-p">Redefining Excellence in Web Design, eCommerce, Custom Development, and Digital Marketing.We take pride in our team of dedicated professionals who work tirelessly to achieve excellence and creativity in every endeavour.</p>
                {expanded && (
                    <div className="expanded-content">
                        <p> As a Web Development and Digital Marketing Company, we've set the benchmark for providing comprehensive website and digital marketing solutions that exceed our customers' expectations.</p>
                        <p>At theLogicalMonk, we've been at the forefront of shaping the digital landscape, crafting futuristic websites and custom web applications for a global clientele. Our success is built on a foundation of unwavering commitment to excellence, innovative thinking, exceptional customer service, and swift project delivery. With a team of highly skilled web developers proficient in cutting-edge technologies like React.JS, Angular.Js, Node.JS, Laravel, Magento, and more, we continually push boundaries and set new standards in web development. As trusted digital consultants, we seamlessly bridge businesses across digital platforms, driving meaningful results.</p>
                    </div>
                )}
                <button className="read-more-btn" onClick={handleReadMore}>
                    {expanded ? "Read Less" : "Read More"}
                </button>
                
                <div className="social-icons">
                    <a href="https://www.facebook.com/profile.php?id=100090720567337">
                        <FaFacebook />
                    </a>
                    <a href="https://www.instagram.com/thelogicalmonks?igsh=OWNibnA0MnQ1dzFo">
                        <FaInstagram />
                    </a>
                    <a href="https://www.linkedin.com/company/93336726/admin/feed/posts/">
                        <FaLinkedin />
                    </a>
                </div>
            </div>
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={closeModal}>&times;</span>
                     <p>Redefining Excellence in Web Design, eCommerce, Custom Development, and Digital Marketing.We take pride in our team of dedicated professionals who work tirelessly to achieve excellence and creativity in every endeavour.</p>
                     <p> As a Web Development and Digital Marketing Company, we've set the benchmark for providing comprehensive website and digital marketing solutions that exceed our customers' expectations.</p>
                     <p>At theLogicalMonk, we've been at the forefront of shaping the digital landscape, crafting futuristic websites and custom web applications for a global clientele. Our success is built on a foundation of unwavering commitment to excellence, innovative thinking, exceptional customer service, and swift project delivery. With a team of highly skilled web developers proficient in cutting-edge technologies like React.JS, Angular.Js, Node.JS, Laravel, Magento, and more, we continually push boundaries and set new standards in web development. As trusted digital consultants, we seamlessly bridge businesses across digital platforms, driving meaningful results.</p>
                  </div>
            </div>
        </div>
    );
}

export default About;