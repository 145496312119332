import React, { useState } from 'react';
import '../../CSS/AboutUs/Discover.css';

const Discover = () => {

  const panelsData = [
    {
      id: 1,
      title: 'Unleashing the Power of Web Development and Web Design Services',
      image: '../Images/webdev.png',
      content: "Unlock Your Business's Unique Potential with Our Tailored Services. From captivating design-led static websites to dynamic CMS solutions, our skilled team ensures your site not only looks great but also delivers outstanding performance. Our design experts focus on creating a visually stunning website with a seamless user interface, while our SEO services guarantee that your site not only attracts but also retains high-quality web traffic through ongoing strategic optimization.",
    },
    {
      id: 2,
      title: 'Custom E-Commerce Solutions ',
      image: '../Images/ecommerce.png',
      content: "Experience the Art of Tailored Design for Your Business Website. As a leading provider of customized eCommerce web development services, we understand the critical need for speed, security, and efficiency in today's online business environment. Our dedicated team of eCommerce developers, backend experts, and managers are committed to delivering top-notch services that align perfectly with your brand's unique requirements. Harness the power of AI-based eCommerce solutions to unlock unparalleled shopping experiences and exceptional user engagement. Elevate your business with an AI-powered eCommerce website and watch your revenue soar to new heights.",
    },
    {
      id: 3,
      title: 'Driving Innovation with Mobile App Development ',
      image: '../Images/mobile.png',
      content: `Transform Your eCommerce Experience with Custom Mobile Apps. We specialize in crafting comprehensive eCommerce mobile app solutions for businesses of all sizes, designed to attract and retain customers while enhancing brand reputation. Our team designs, develops, and deploys mobile apps across major platforms including iOS, Android, and Windows Mobile. Our mobile app development services include:
        <ul>
          <li>Thorough analysis of business needs and requirements.</li>
          <li>User Experience (UX) testing to ensure seamless functionality and user satisfaction.</li>
        </ul>
        Experience flawless and user-friendly mobile applications for your eCommerce business, providing unparalleled service to your customers and driving business growth.`,
    },
    {
      id: 4,
      title: 'Elevating Brands through Digital Marketing Excellence',
      image: '../Images/digital2.png',
      content: "We offer a comprehensive range of digital marketing services, including Social Media Marketing, Lead Generation, Content Creation, and Blog Marketing, all designed to create memorable digital experiences and engage your audience effectively. Our approach is rooted in creativity, with a dedicated team that thrives on thinking outside the box to deliver innovative and beautiful campaigns consistently.Using a data-driven strategy, we aim to drive business growth through digital channels. Our innovative solutions harness the power of digital platforms to unlock your growth potential and elevate your brand's visibility and impact. We take the time to understand your organization and your customers, crafting a digital strategy that sets your brand apart and delivers tangible results.",
     },
    
  ];

 
  const [openPanels, setOpenPanels] = useState([]);

 
  const togglePanel = (panelId) => {
    if (openPanels.includes(panelId)) {
      setOpenPanels(openPanels.filter(id => id !== panelId));
    } else {
      setOpenPanels([...openPanels, panelId]);
    }
  };

  return (
    <div className='discover-div'>
      <h2 className='discover-h2'>Discover Our Comprehensive Solutions</h2>
    <div className="panels-container">
      {panelsData.map(panel => (
        <div className="panel-container" key={panel.id}>
          <div className="panel-header" onClick={() => togglePanel(panel.id)}>
            <img src={panel.image} alt={panel.title} className="panel-header-img" />
            <span className="panel-header-title">{panel.title}</span>
          </div>
          {openPanels.includes(panel.id) && (
            <div className="panel-content" dangerouslySetInnerHTML={{ __html: panel.content }}></div>
          )}
        </div>
      ))}
    </div>
    </div>
  );
};

export default Discover;