import React, { useRef } from "react";

import '../../../CSS/Services/MobApp/MobAppServices.css';

function MobAppServices(){
    const servicesCardDivRef = useRef(null); // Create a reference to the services-card-div

    const servicesData=[
        {
            imgSrc:'../Images/ui-ux.png',
            title:"Android UI/UX Design",
            text:"Devoted to captivating crafting designs centered around the user experience, we pave the way for your success. Our visually stunning creations not only speak volumes for your brand but also reflect our dedication to turning your dream projects into reality with passion and precision."
        },
        {
            imgSrc:'../Images/android.png',
            title:"Custom Android App Development",
            text:"We excel in crafting native app solutions, harnessing the power of Java or Kotlin languages alongside the Android SDK. Our developers are adept at delivering efficient code that not only fulfills objectives but also elevates platform usability and performance."
        },
        {
            imgSrc:'../Images/api.png',
            title:"Backend Development and API Integration",
            text:"We empower your app with seamless communication capabilities and establish a robust, secure foundation for your business. Our expertise extends to integrating third-party APIs seamlessly, including geolocation, social media, payment gateways, and more, enhancing functionality and user experience."
        },
        {
            imgSrc:'../Images/support.png',
            title:"Maintenance and Support",
            text:"We recognize the importance of a fully functional Android app and the ongoing need for seamless operation. We offer comprehensive maintenance and support services, allowing you to concentrate on your business needs while we handle the technical intricacies, ensuring a smooth and hassle-free experience for you."
        },
    ]

    const handleImageClick = () => {
        
        servicesCardDivRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return(
        <div className="mobappservices-div">
            <div className="service">
               <h2>Empower your enterprise with our leading-edge services tailored for success</h2>
               <p>We specialize in delivering innovative solutions that propel your business forward, leveraging the latest technologies to unlock new opportunities and drive growth.</p>
               <img src="../Images/mobappstep1.png" alt="mobapp-roadmap" className="services-img" onClick={handleImageClick} />
            </div>
            <div className="services-card-div" ref={servicesCardDivRef}>
                {servicesData.map((service, index) => (
                    <div key={index} className="services-card">
                        <img src={service.imgSrc} className="services-card-img" onClick={handleImageClick} />
                        <h3 className="services-card-h3">{service.title}</h3>
                        <p>{service.text}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MobAppServices;