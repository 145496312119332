//Successjourney.jsx
import React from 'react';
import "../../../CSS/Services/Ecommerce/Successjourney.css";

function Successjourney() {
  
  const boxesData = [
    {
      title: "Requirement Plan",
      content: "Empowering Your Audience: Our approach begins with a deep understanding of your audience, mapping out their journey on your website. Leveraging your insights, our eCommerce web design team crafts a precise target persona, selects the optimal platform, and engineers an exceptional user experience for your eCommerce triumph."
    },
    {
      title: "Design and Development",
      content: "Our customer-centric approach to eCommerce website design ensures an intuitive user experience, driving repeat visits. We meticulously develop custom features to align with your unique requirements, ensuring seamless functionality and customer satisfaction."
    },
    {
      title: "Testing and Deployment",
      content: "We provide steadfast support throughout the testing and launch phases of your eCommerce website. Our rigorous quality assurance and user acceptance testing guarantee a secure, vulnerability-free website ready to make its mark in the digital realm."
    }
  ];

  return (
    <div className="successjourneyheading">
      <h2>Unlocking E-Commerce Success: Our Proven E-Commerce Development Journey</h2>

      <div className="boxcontainer">
        {boxesData.map((box, index) => (
          <div key={index} className={`box1${index + 1}`}>
            <div className="box"> 
              <h2>{box.title}</h2>
              <p>{box.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Successjourney;
