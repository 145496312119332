import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../CSS/Services/WebDev/Technologies.css";


const Technologies = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 9, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500, 
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 9,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 9,
        },
      },
    ],
  };

  return (
    <div className="weblogocarousel">

      <div className="weblogocarouselheading">
        <h2>Web Technologies We Work On</h2>
      </div>
      <div className="weblogocarouselimg">
      
        <Slider {...settings}>
          <div>
            <img src="../Images/html5logo.svg" alt="Html Logo"  className='weblogo'/>
          </div>
          <div>
            <img src="../Images/csslogo.svg" alt="Css Logo"  className='weblogo'/>
          </div>
          <div>
            <img src="../Images/nodejslogo.svg" alt="Node Logo"  className='weblogo'/>
          </div>
          <div>
            <img src="../Images/angularlogo.svg" alt="Angular Logo"  className='weblogo'/>
          </div>
          <div>
            <img src="../Images/reactjslogo.svg" alt=" React Logo "  className='weblogo'/>
          </div>
          <div>
            <img src="../Images/expressjslogo.svg" alt="Express Logo"  className='weblogo'/>
          </div>
          <div>
            <img src="../Images/javascriptlogo.svg" alt="Js Logo"  className='weblogo'/>
          </div>
          <div>
            <img src="../Images/djangologo.svg" alt="Django Logo"  className='weblogo'/>
          </div>
          <div>
            <img src="../Images/restapilogo.svg" alt="RestApi Logo"  className='weblogo'/>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Technologies;




