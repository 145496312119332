import React from "react";
import '../../CSS/AboutUs/MissionVision.css';

function MissionVision(){
    return(
        <div className="mission-vision">
           <div className="mission-vision-div">

            <div className="mission">
                <div className="mission-img-div">
                <img src="../Images/mission.png" alt="mission-logo" className="mission-vision-img"/>
                </div>
                <div className="mission-content">
                <h2>Our Mission</h2>
                <p>To revolutionize the global landscape of Web Development and Digital Marketing Services, our mission is to pioneer innovative solutions that drive business growth ethically and with unmatched quality. Customer trust and timely project delivery are at the core of our values. We measure success by the tangible value we bring to our clients, solving their challenges with precision and expertise. Our commitment to excellence is upheld by industry best practices and a team of seasoned professionals dedicated to delivering world-class products that offer exceptional value for our global clientele.</p>
                </div>
            </div>

            <div className="vision">
                <div className="vision-img-div">
                <img src="../Images/vision.png" alt="vision-logo" className="mission-vision-img" />
                </div>
                <div className="vision-content">
                <h2>Our Vision</h2>
                <p>Empowering Growth with Tailored Solutions. Our commitment is to deliver unparalleled web development and digital marketing services that set you apart from the competition. We strive to be your preferred partner, crafting multi-dimensional digital experiences that authentically reflect your brand. With a focus on reliability and quality, we aim to exceed expectations and provide exceptional value to clients worldwide.</p>
                </div>
            </div>

           </div> 
        </div>
    )
}
export default MissionVision;