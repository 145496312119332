import React from 'react';
import '../../../CSS/Services/DigiMarket/DigiServices.css';
import { IconContext } from 'react-icons';
import { FaSearchengin, FaBullhorn, FaFacebookSquare, FaPen, FaEnvelope, FaChartLine } from 'react-icons/fa';

const Icon = ({ icon, size = '24', color = '#000', className }) => {
  const IconComponent = icon;
  
  return (
    <IconContext.Provider value={{ size, color }}>
      <IconComponent className={className} />
    </IconContext.Provider>
  );
};

const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className="digi-service-card">
      <div className="digi-service-icon">
        <Icon icon={icon} size="48" color="#333" />
      </div>
      <h3 className="digi-service-title">{title}</h3>
      <p className="digi-service-description">{description}</p>
    </div>
  );
};

const DigiServices = () => {
  const services = [
    {
      title: "Search Engine Domination (SEO)",
      description: "Rise above the competition and dominate search engine rankings with our advanced SEO strategies, tailored to increase organic traffic, improve visibility, and boost conversions.",
      icon: FaSearchengin
    },
    {
      title: "Strategic Paid Advertising (PPC)",
      description: "Accelerate your growth with targeted PPC campaigns that deliver instant visibility, high-quality leads, and maximum ROI across Google Ads, social media platforms, and more.",
      icon: FaBullhorn
    },
    {
      title: "Engagement-Driven Social Media Mastery (SMM)",
      description: "Harness the power of social media to build a loyal community, drive engagement, and fuel brand advocacy through compelling content, strategic campaigns, and real-time interaction.",
      icon: FaFacebookSquare
    },
    {
      title: "Content Excellence",
      description: "Captivate your audience with captivating content that educates, inspires, and converts. Our content marketing expertise spans from SEO-optimized blogs and videos to impactful social media posts and email campaigns.",
      icon: FaPen
    },
    {
      title: "Email Automation and Personalization",
      description: "Cultivate lasting relationships with your audience through personalized email campaigns that nurture leads, drive conversions, and maximize customer lifetime value.",
      icon: FaEnvelope
    },
    {
      title: "Data-Driven Insights and Analytics",
      description: "Leverage actionable insights and real-time data analytics to make informed decisions, optimize campaigns for performance, and achieve measurable business growth.",
      icon: FaChartLine
    }
  ];

  return (
    <div className="digi-services-container"> 
    <h2>Our Digital Marketing Services Include</h2>
      <div className="digital-marketing-services">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            icon={service.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default DigiServices;