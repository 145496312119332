import React from 'react'
import Contact from './Contact'

export default function Contactus() {
  return (
    <div>
      <Contact />
    </div>
  )
}
