import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../../CSS/Services/WebDev/Webhomepage.css";

function Webhomepage() {
  return (
    <div className='webhomepageheading'>
      <h2>Crafting Seamless Web Experiences from Inception to Launch</h2>
      <div className='webhomepage'>
        <div className='webhomepageimg'>
          <Carousel showArrows={false} showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true}  interval={2000} transitionTime={1000}>
            <div>
               <img src="../Images/webhomeimage1.png" alt="Webdev image" className="Webdev" />
            </div>
            <div>
               <img src="../Images/webhomeimage2.png" alt="Webdev image" className="Webdev" />
            </div>
            <div>
               <img src="../Images/webhomeimage3.png" alt="Webdev image" className="Webdev" />
            </div>
            <div>
              <img src="../Images/webhomeimage4.png" alt="Webdev image" className="Webdev" />
            </div>
          </Carousel>
        </div>
        <div className='webhomepagecontent'>
          <p>Revolutionize Your Digital Presence with Customized Web Solutions</p>
          <ul>
            <li>Dynamic and Interactive Web Pages.</li>
            <li>Unique Web Designs that Elevate Your Brand Identity.</li>
            <li>Intuitive User Interface Designs for Seamless Navigation.</li>
            <li>Seamless Integration with Cutting-edge Content Management Systems.</li>  
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Webhomepage;
