//Requestacallback.jsx
import React from 'react';
import '../../../CSS/Services/DigiMarket/DigiRequestaCallback.css';



function DigiRequestacallback() {
  
  return (
    <div className='Digimessagepage'>
     <div className='DigiRequestacallbackimg'>
       <img src="../Images/Requestacallbackimg.png" alt="Hand Showing Image" className="Digihandimg"  ></img>
     </div>
    
     <div className='Digimessageform'>
     <div className='Digimessageheading'>
      <h1>Request A Callback </h1>
    </div>
       <form
       action="https://formspree.io/f/xdoqwoyg"
       method="POST"
       >
      <label>Name:</label>
      <input type="text" name="user_name" required className='Digimessageformcontent'/>
      <label>Phone:</label>
      <input type="tel" name="user_phone" required className='Digimessageformcontent'/>
      <label>Email:</label>
      <input type="email" name="user_email" required className='Digimessageformcontent'/>
      <label>Message:</label>
      <textarea id="message" name="message" required className='Digimessageformcontent'></textarea>
      <input type="submit" value="Send Message" className='Digisendbutton' />
      </form>
     </div>
    </div>
  
  );
}

export default DigiRequestacallback;