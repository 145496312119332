import React from 'react';
import '../../../CSS/Services/DigiMarket/DigiPartner.css';

const DigiPartner = () => {
   
    
  return (
    <>
    <div className="digipartnerheading">
       <h2>Why Partner with us for Digital Marketing?</h2>
    </div>
    <div className='digipartnercontainer'>
    
    
          <div className="digitalpartercontent">
          <img src='../Images/digistrategy.png' alt="strategy" className='digistrategyimg' />
             <h3>Strategic Approach</h3>
            <p> We take a holistic approach to digitalmarketing, aligning our strategies with your business goals, targetaudience, and industry trends to drive sustainable growth.</p>
          </div>
          <div className="digitalpartercontent">
          <img src='../Images/digicreative.png' alt="Creative" className='digiCreativeimg'  />
             <h3>Creative Excellence</h3>
            <p>Our team of creative minds and marketing wizards craft compelling campaigns that resonate with your audience,spark engagement, and inspire action.</p>
          </div>
          <div className="digitalpartercontent">
          <img src='../Images/digitechnology.png' alt="Technology" className='digiTechnologyimg' />
             <h3>Technology Advantage</h3>
            <p>Stay ahead of the curve with our expertise in leveraging the latest digital tools, AI-driven solutions, and automation to streamline processes and maximize results.</p>
          </div>
          <div className="digitalpartercontent">
          <img src='../Images/digicollaboration.png' alt="Collaboration"  className='digiCollaboration'/>

             <h3> Transparent Collaboration</h3>
            <p>Experience seamless collaboration and transparent communication throughout your digital journey with regular updates, performance reports, and actionable insights.</p>
          </div>
          <div className="digitalpartercontent">
          <img src='../Images/digiresults.png' alt="Results"  className='digiresultsimg' />
             <h3>Measurable Results</h3>
             <p>We're not just about fancy campaigns; we're about delivering measurable results. Our focus is on driving conversions, increasing ROI, and achieving tangible business outcomes for our clients..</p>
          </div>
    </div>
    </>
  );
};

export default DigiPartner;


