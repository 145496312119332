import React from 'react'
import MobileHome from './MobileHome'
import AndroidappDev from './AndroidappDev'
import MobAppServices from './MobAppServices'
import AndroidappChoose from './AndroidappChoose'
import MobRequestcallback from './MobRequestcallback'

export default function MobApp() {
  return (
    <div>
      <MobileHome />
      <AndroidappDev />
      <MobAppServices />
      <AndroidappChoose />
      <MobRequestcallback />
    </div>
  )
}
