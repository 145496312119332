import React from "react";
import '../../../CSS/Services/Ecommerce/Ecommerce-Partnership.css';

function Partnership(){
    const partnerData=[
        {
            imgSrc: '../Images/Exemplary Customer Experience.jpg',
            title: 'Exemplary Customer Experience',
            text: "Experience seamless ecommerce development with our round-the-clock support, available 24/7 for all your needs. Our expert team ensures quick responses and technical expertise, guaranteeing a smooth and efficient process. From troubleshooting to proactive monitoring, we've got you covered day and night. Elevate your online business with our comprehensive support, tailored to exceed your expectations. Trust us for continuous assistance and unparalleled customer satisfaction in ecommerce development."
        },
        {
            imgSrc: '../Images/Cutting-Edge Technological Innovations.jpg',
            title: 'Cutting-Edge Technological Innovations',
            text: 'Transform your ecommerce experience with our cutting-edge technology solutions, driving excellence in every aspect. Our innovative approach ensures seamless integration, optimal performance, and unmatched user experience. Leverage the latest tech advancements to stay ahead in the competitive market. Partner with us for unparalleled expertise in ecommerce development, tailored to your unique needs. Experience the power of technology in elevating your online business to new heights.'
        },
        {
            imgSrc: '../Images/Exceptional Industry Expertise.jpg',
            title: 'Exceptional Industry Expertise',
            text: 'Rely on our elite team for exceptional results in every stage of your ecommerce development journey. Our experts bring unparalleled expertise and dedication to every project. From planning to execution, we ensure excellence at every step. Trust us to elevate your online presence with tailored solutions and top-notch support. Experience excellence firsthand with our committed team by your side.'
        },
        {
            imgSrc: '../Images/Rapid Delivery.jpg',
            title: 'Rapid Delivery',
            text: 'Experience efficient delivery that meets your exact requirements in ecommerce development. Our streamlined process ensures precise implementation of your vision. From concept to execution, we deliver exactly what you need, on time and within budget. Trust us to bring your ideas to life with precision and expertise. Count on us for seamless execution and results that exceed your expectations.'
        },
        {
            imgSrc: '../Images/Rigorous Quality Assurance.jpg',
            title: 'Rigorous Quality Assurance',
            text: 'Embark on a journey of unparalleled success with our ecommerce development services, where we consistently exceed expectations. Our dedicated team goes above and beyond to deliver exceptional results, every time. Experience innovation, precision, and excellence that redefine your online presence. Trust us for a partnership that consistently delivers beyond what you imagined possible. Join us in setting new standards of success in ecommerce development.'
        },
    ]
    return(
        <div className="partnership">
            <h2 className="partnership-h1">Discover the Benefits of Partnering with Us</h2>
            <div className="partnership-card">
            {partnerData.map((index) => (
                <div key={index} className="partner-card">
                    <h3 className="partner-card-h3">{index.title}</h3>
                    <img src={index.imgSrc} className="partner-card-img" />
                    <p className="partner-card-p">{index.text}</p>
                </div>
            ))}
            </div>
               
        </div>
    )
}

export default Partnership;