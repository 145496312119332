import React from "react";
import '../../../CSS/Services/WebDev/WebDev-Partnership.css';

function WebPartnership(){
    const partnerData=[
        {
            imgSrc: '../Images/Seasoned and Skilled Developers.png',
            title: 'Seasoned and Skilled Developers:',
            text: "Our team comprises seasoned and skilled developers who are passionate about leveraging the latest technologies to deliver top-notch website development services. With a track record of successful projects across diverse business niches globally, we bring expertise and innovation to every project."
        },
        {
            imgSrc: '../Images/Open and Transparent Communication.png',
            title: 'Open and Transparent Communication:',
            text: 'We prioritize open and transparent communication, keeping you informed about project progress through various channels like Skype, Email, or phone. Our 24/7 availability ensures prompt assistance and seamless collaboration throughout the development journey.'
        },
        {
            imgSrc: '../Images/Strict Non-Disclosure Policy.png',
            title: 'Strict Non-Disclosure Policy: ',
            text: 'We prioritize the security and confidentiality of your website development ideas. Our strict non-disclosure agreement (NDA) ensures that your project details remain safe and secure throughout the development process. We never disclose any information without your explicit consent.'
        },
        {
            imgSrc: '../Images/On-Time Project Delivery.png',
            title: 'On-Time Project Delivery: ',
            text: 'As a reputable website development company, we understand the importance of timely delivery. Leveraging the latest technologies and efficient development processes, we ensure that your project is delivered within the agreed timeframe without compromising quality.'
        },
        {
            imgSrc: '../Images/Transparent and Secure Operations.png',
            title: 'Transparent and Secure Operations:',
            text: 'We uphold complete transparency in our operations, safeguarding your information and project details with utmost care. Our commitment to confidentiality and integrity is reinforced by our NDA, ensuring a secure and trustworthy partnership."'
        },
    ]
    return(
        <div className="web-partnership">
            <h2 className="web-partnership-h1">Why Partner with Us for Your Website Development Needs? </h2>
            <h3 className="web-partnership-h3">Discover the key differentiators that set us apart in the realm of website development:</h3>
            <div className="web-partnership-card">
            {partnerData.map((index) => (
                <div key={index} className="web-partner-card">
                  <h3 className="web-partner-card-h3">{index.title}</h3>
                  <p className="web-partner-card-p">{index.text}</p>
                  <img src={index.imgSrc} className="web-partner-card-img" />
                </div>
            ))}
            </div>
               
        </div>
    )
}

export default WebPartnership;