//Requestacallback.jsx
import React from 'react';
import '../../../CSS/Services/WebDev/WebDevrequestcallback.css';



function WebDevrequestcallback() {
  
  return (
    <div className='webmessagepage'>
     <div className='webRequestacallbackimg'>
       <img src="../Images/Requestacallbackimg.png" alt="Hand Showing Image" className="webhandimg"  ></img>
     </div>
    
     <div className='webmessageform'>
     <div className='webmessageheading'>
      <h1>Request A Callback </h1>
    </div>
       <form
       action="https://formspree.io/f/xdoqwoyg"
       method="POST"
       >
      <label>Name:</label>
      <input type="text" name="user_name" required className='webmessageformcontent'/>
      <label>Phone:</label>
      <input type="tel" name="user_phone" required className='webmessageformcontent'/>
      <label>Email:</label>
      <input type="email" name="user_email" required className='webmessageformcontent'/>
      <label>Message:</label>
      <textarea id="message" name="message" required className='webmessageformcontent'></textarea>
      <input type="submit" value="Send Message" className='websendbutton' />
      </form>
     </div>
    </div>
  
  );
}

export default WebDevrequestcallback;

