import React from 'react';
import '../../../CSS/Services/MobApp/AndroidappDev.css';

const AndroidappDev = () => {
  return (
    <>
    <div className="androidheading">
       <h2>Our Android App Development Services include</h2>
    </div>
    <div className='androidcontainer'>
    
    
          <div className="androidcontent">
             <h3>UI/UX Design</h3>
            <p>Craft visually appealing and user-friendly interfaces that enhance the overall app experience.</p>
          </div>
          <div className="androidcontent">
             <h3>App Upgradation</h3>
            <p>Keep your app up-to-date with the latest features and technologies to stay ahead in the market.</p>
          </div>
          <div className="androidcontent">
             <h3>App Maintenance</h3>
            <p> Ensure smooth operation and performance with regular maintenance and updates.</p>
          </div>
          <div className="androidcontent">
             <h3>Customized App Development</h3>
            <p>Tailor-made solutions to meet your unique business requirements and objectives.</p>
          </div>
          <div className="androidcontent">
             <h3>Hybrid Android App Development</h3>
            <p>Develop versatile apps that work seamlessly across multiple platforms for broader reach and accessibility.</p>
          </div>
    </div>
    </>
  );
};

export default AndroidappDev;