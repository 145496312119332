import React from 'react'
import '../../CSS/ContactUs/Contact.css'; 
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoIosTime } from "react-icons/io";
import { FaEnvelope } from 'react-icons/fa';


const Contact = () => {
    return (
      <div className='contact-container'>
           <div className='item'>
                <h2>Contact Us</h2>
              <div className='contact-address'>
                  <p><FaLocationDot /> No : 7/3 Shanthi Nivas,<br />
                       GroundFloor,
                       Madley Road,<br />
                       Ashoka Nagar,
                       T.Nagar,<br />
                       Chennai - 600017.</p>
              </div>
  
              <div className='contact-social-icons'>
                   <p> <FaPhone />+91 9080718468 </p>
                   <p> <IoIosTime /> We are open Mon-Sat 10am - 7:00pm</p>
                   <p> <FaEnvelope /> Email : hr@thelogicalmonks.in</p>
              </div>
           </div>
  
           <div className="map-container">
                 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31096.127044190882!2d80.19172181083984!3d13.03466060000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267faea603c37%3A0xa95fb9212f654681!2sThe%20Logical%20Monks!5e0!3m2!1sen!2sin!4v1713862754691!5m2!1sen!2sin" 
                  width="600" 
                  height="450" 
                  allowfullscreen="" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade">
                </iframe>
           </div>
      </div>
    );
  };
  

export default Contact;