import React from 'react'
import '../../../CSS/Services/Ecommerce/Roadmap.css'; 

function Roadmap() {
  return (
    <div className='roadmap'>

    
<div className='imagecontent'>

<img src='../Images/steps.png' alt='milestone achievements' id='img'/>


<div className="content">
   <h2 className="heading">Elevate Customer Satisfaction with a Cutting-Edge
  Shopping Experience for the Modern Consumer</h2>
    <p className='paragraph'>As the eCommerce landscape evolves, staying ahead demands more than ever.We specialize in crafting seamless, secure, and visually captivating  performance optimization,shopping experiences through meticulous UX/UI design,and comprehensive services, ensuring tangible results for your business.</p>
</div>
</div>
</div>
  )
}

export default Roadmap;