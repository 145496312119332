import React from 'react';
import '../../../CSS/Services/MobApp/MobRequestcallback.css';



function MobRequestcallback() {
  
  return (
    <div className='mobmessagepage'>
     <div className='mobRequestacallbackimg'>
       <img src="../Images/Requestacallbackimg.png" alt="Hand Showing Image" className="mobhandimg"  ></img>
     </div>
    
     <div className='mobmessageform'>
     <div className='mobmessageheading'>
      <h1>Request A Callback </h1>
    </div>
       <form
       action="https://formspree.io/f/xdoqwoyg"
       method="POST"
       >
      <label>Name:</label>
      <input type="text" name="user_name" required className='mobmessageformcontent'/>
      <label>Phone:</label>
      <input type="tel" name="user_phone" required className='mobmessageformcontent'/>
      <label>Email:</label>
      <input type="email" name="user_email" required className='mobmessageformcontent'/>
      <label>Message:</label>
      <textarea id="message" name="message" required className='mobmessageformcontent'></textarea>
      <input type="submit" value="Send Message" className='mobsendbutton' />
      </form>
     </div>
    </div>
  
  );
}

export default MobRequestcallback;