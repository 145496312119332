import React, { useState } from 'react';
import '../../../CSS/Services/WebDev/Methodology.css';

function Methodology() {
  const designCard = [
    {
        imgSrc: '../Images/understanding.png',
        title: 'UnderStanding Your Needs',
        content: "We start by delving deep into your project requirements, gaining valuable insights that shape our approach. Through competitor research and market analysis, we develop a clear understanding of your goals and audience."
      },
      {
        imgSrc: '../Images/Strategic.png',
        title: 'Strategic Planning and Wireframing',
        content: "Next, we map out the site's structure and navigation, creating a blueprint for a seamless user experience. Our wireframes and prototypes bring concepts to life, allowing for iterative refinement and client feedback."
      },
      {
        imgSrc: '../Images/Design execution.png',
        title: 'Elegant Design Execution',
        content: "Our design phase focuses on aesthetics and functionality, blending colors, typography, and imagery to create visually stunning and user-friendly interfaces. We prioritize user experience (UX) and interface design (UI) for optimal engagement."
      },
      {
        imgSrc: '../Images/Expert development.png',
        title: 'Expert Development',
        content: "As a top-tier website design company, we translate designs into code, ensuring responsiveness and compatibility across devices and browsers. Our development process is meticulous, guaranteeing a flawless end product."
      },
      {
        imgSrc: '../Images/Compelling content.png',
        title: 'Compelling Content Creation',
        content: "Content is king, and we craft or optimize content to align perfectly with design and user experience. Whether it's in-house expertise or collaboration with clients, we ensure content enhances the overall website impact."
      },
      {
        imgSrc: '../Images/testing.png',
        title: 'Thorough Testing and Quality Assurance',
        content: "Our rigorous testing phase covers all bases, identifying and addressing any issues to ensure a flawless performance. We leave no stone unturned in guaranteeing a stellar user experience."
      },
      {
        imgSrc: '../Images/Deployment.png',
        title: 'Smooth Deployment Process',
        content: "As your trusted website design partner, we handle deployment with precision, setting up domains, hosting, and configurations seamlessly. Our goal is to make the transition to a live server smooth and hassle-free."
      }
   
  ];

  
  const [hoveredCard, setHoveredCard] = useState(null);

  return (
    <div className='methodology-container'>
      <h2 className="designheading">Our Proven Website Design Methodology </h2>
      <h3 className="designpara">We've honed our website design process to perfection, ensuring every project delivers exceptional results and exceeds expectations.</h3>
      
      <div className='cardDesign'>
        {designCard.map((card, index) => (
          <div key={index} className='card' 
               onMouseEnter={() => setHoveredCard(index)}
               onMouseLeave={() => setHoveredCard(null)}>
            <div className="cardContainer">
              <h4>{card.title}</h4>
              <img src={card.imgSrc} className='cardimage' alt={card.title}/>
              {hoveredCard === index && (
                <div className="hoveredContent">
                  <h4>{card.title}</h4>
                  <p>{card.content}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Methodology;


