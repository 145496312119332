import React from 'react';
import '../../../CSS/Services/MobApp/AndroidappChoose.css';

const AndroidappChoose = () => {
   
    
  return (
    <>
    <div className="chooseheading">
       <h2>Why choose us for mobile application development</h2>
    </div>
    <div className='choosecontainer'>
    
    
          <div className="choosecontent">
          <img src='../Images/appexpertise.png' alt="Expertise" className='Expertiseimg' />
             <h3>Expertise</h3>
            <p>Our team comprises seasoned professionals with extensive experience in mobile app development, ensuring top-notch solutions.</p>
          </div>
          <div className="choosecontent">
          <img src='../Images/appinnovation.png' alt="Innovation" className='Innovationimg'  />
             <h3>Innovation</h3>
            <p>We stay ahead of the curve with innovative technologies and creative solutions that set your app apart.</p>
          </div>
          <div className="choosecontent">
          <img src='../Images/appcustomization.png' alt="Customization" className='Customizationimg' />
             <h3>Customization</h3>
            <p>Tailored approaches to meet your unique business needs and objectives, delivering personalized experiences.</p>
          </div>
          <div className="choosecontent">
          <img src='../Images/appassurance.png' alt="Quality Assurance"  className='Qualityassuranceimg'/>

             <h3>Quality Assurance</h3>
            <p>Rigorous testing and quality checks to ensure flawless performance and user satisfaction</p>
          </div>
          <div className="choosecontent">
          <img src='../Images/appsupport.png' alt="Support"  className='Supportimg' />
             <h3>Support</h3>
             <p>Dedicated support and maintenance services post-launch, ensuring ongoing success and growth for your app.</p>
          </div>
    </div>
    </>
  );
};

export default AndroidappChoose;


