import React from 'react'
import Homepage from './Homepage'
import Roadmap from './Roadmap'
import Successjourney from './Successjourney'
import Partnership from './Ecommerce-Partnership'
import Requestacallback from './Requestacallback'

export default function Ecommerce() {
  return (
    <div>
      <Homepage />
      <Roadmap />
      <Successjourney />
      <Partnership />
      <Requestacallback />
    </div>
  )
}
