import React from 'react';
import "../../CSS/AboutUs/Core.css";

const Core = () => {
  return (
    
    <div className='core'>
    <div className="coreheading">
       <h2>Our Core Principles</h2>
    </div>
    <div className="core-carddata">
      <div className="corecard">
          <div className="corecardfront">
               <h2>Collaboration</h2>
             <div>
               <img src="../Images/Collaboration.png" alt=" Collaboration image" className="coreimg" />
             </div>
          </div>
          <div className="corecardback">
             <h3>Collaboration</h3>
            <p>Collaboration is the cornerstone of our success. Our diverse team of Web Developers, Digital Marketers, Graphic Designers, and technical experts work seamlessly together to achieve excellence. We foster a culture of continuous learning, skill development, and mutual support, ensuring that every member contributes to our collective success.</p>
          </div>
      </div>


      <div className="corecard">
          <div className="corecardfront">
               <h2>Innovation</h2>
             <div>
               <img src="../Images/Innovation.png" alt="Innovation image" className="coreimg" />
             </div>
          </div>
          <div className="corecardback">
             <h3>Innovation</h3>
            <p>We are committed to constant growth and smart service production. Embracing new technologies and industry best practices, we continuously monitor, inspect, and measure our processes to deliver unmatched services and stay ahead of the curve.</p>
          </div>
      </div>



      <div className="corecard">
          <div className="corecardfront">
               <h2>Integrity</h2>
             <div>
               <img src="../Images/Integrity.png" alt="Integrity image" className="coreimg" />
             </div>
          </div>
          <div className="corecardback">
             <h3>Integrity</h3>
            <p>Integrity is at the heart of everything we do. Our commitment to honesty, reliability, and timely project delivery is unwavering. We uphold our promises and dedication, earning the trust and loyalty of our clients through consistent excellence.</p>
          </div>
      </div>



      <div className="corecard">
          <div className="corecardfront">
               <h2>Respect</h2>
             <div>
               <img src="../Images/Respect.png" alt="Respect image" className="coreimg" />
             </div>
          </div>
          <div className="corecardback">
             <h3>Respect</h3>
            <p>Our clients are our most valuable asset, and their satisfaction is our top priority. We deeply value and respect our clients, understanding that their success is intertwined with ours. Our dedication to providing exceptional service and achieving excellence is fueled by the satisfaction and trust of our clients.</p>
          </div>
      </div>



    </div>

    </div>
  );
};

export default Core;