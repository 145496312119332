import React from 'react'
import Digihome from './Digihome'
import DigiPartner from './DigiPartner'
import DigiServices from './DigiServices'
import DigiRequestacallback from './DigiRequestaCallback'

export default function DigiMarket() {
  return (
    <div>
      <Digihome />
      <DigiServices />
      <DigiPartner />
      <DigiRequestacallback />
    </div>
  )
}
